var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-list-group",
    {
      staticClass: "list-group",
      attrs: {
        group: _vm.item.group,
        "active-class": _vm.$config(
          "settings.layoutColors.activeGroupItemClass"
        ),
        ripple: false,
        "append-icon": "mdi-chevron-down-circle"
      },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function() {
            return [
              _c(
                "v-list-item-action",
                [
                  _c("v-icon", {
                    staticClass: "list-item-icon ml-2",
                    class: _vm.$config(
                      "settings.layoutColors.sideBarGroupText"
                    ),
                    domProps: { textContent: _vm._s(_vm.item.icon) }
                  })
                ],
                1
              ),
              _c(
                "v-list-item-title",
                {
                  staticClass: "font-weight-regular text-subtitle",
                  class: _vm.$config("settings.layoutColors.sideBarGroupText")
                },
                [_vm._v(_vm._s(_vm.item.text))]
              )
            ]
          },
          proxy: true
        }
      ])
    },
    _vm._l(_vm.item.children, function(child, index) {
      return _c(
        "v-list-item",
        { key: index },
        [
          _c("sidebar-item", {
            attrs: { item: child, "item-class": "ml-n2 mr-n4" }
          })
        ],
        1
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }